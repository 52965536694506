import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
import { createAuth0 } from "@auth0/auth0-vue";
import * as ElementPlusIconsVue from '@element-plus/icons-vue'

const app = createApp(App);
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}
app.use(store);
app.use(router);
app.use(ElementPlus);
app.use(
  createAuth0({
    domain: process.env.VUE_APP_DOMAIN,
    client_id: process.env.VUE_APP_CLIENTID,
    redirect_uri: window.location.origin,
    audience: process.env.VUE_APP_AUTH0APIAUDIENCE,
  })
);
app.mount("#app");
