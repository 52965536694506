<template>
  <div class="common-layout">
    <el-container>
      <el-header>
        <nav>
          <el-menu
            default-active=1
            class="el-menu-demo"
            mode="horizontal"
            :ellipsis="false"
            @select="setMenuIndex"
          >
            <img
              src="../assets/images/website-logo-200px.png"
              width="50"
              height="50"
            />
            <el-menu-item class="noStyle" @click="openDrawer">
              Menu
            </el-menu-item>
            <el-menu-item
              index=2
              class="noStyle"
              @click="this.$router.push(`/myprojects`)"
              >My Dashboard</el-menu-item
            >
            <div class="flex-grow" />
            <el-menu-item
              index=3
              class="noStyle"
              @click="this.$router.push(`/`)"
              ><span class="logoFont" style="cursor: pointer">Ori & Ollie</span
              ><span class="pageTitle"
                >: {{ $store.state.pageTitle }}</span
              ></el-menu-item
            >
            <div class="flex-grow" />
            <el-menu-item class="noStyle" index=4 disabled>Blog</el-menu-item>
            <el-menu-item class="noStyle" index=4 disabled
              >Help!</el-menu-item
            >
            <el-menu-item @click="logout" class="noStyle" v-if="isAuthenticated"
              >Log Out</el-menu-item
            >
            <el-menu-item @click="login" class="noStyle" v-else
              >Log In</el-menu-item
            >
          </el-menu>
        </nav></el-header
      >
    </el-container>
  </div>
</template>

<script>
import { useAuth0 } from "@auth0/auth0-vue";

export default {
  name: "NavBar",
  components: {},
  data() {
    return {
      logoURL: "assets/images/website-logo-200px.png",
    };
  },
  setup() {
    const { loginWithRedirect, logout, isAuthenticated } = useAuth0();
    return {
      login: () => {
        loginWithRedirect();
      },
      logout: () => {
        logout({ returnTo: window.location.origin });
      },
      isAuthenticated,
    };
  },
  mounted() {},
  created() {},
  computed: {
    pageTitle() {
      return this.$store.getters.getPageTitle;
    },
    menuIndex() {
      return this.$store.getters.getMenuIndex;
    },
  },
  watch: {},
  methods: {
    openDrawer() {
      this.$store.commit("setisMenuDrawerOpen");
    },
    setMenuIndex(key) {
      this.$store.commit("setMenuIndex", key);
    },
    setIsNewBoxAvailable() {
      this.$store.commit("setIsNewBoxAvailable");
    },
  },
};
</script>

<style scoped>
@font-face {
  font-family: "BlissfullySmooth";
  src: local("BlissfullySmooth"),
    url(../assets/fonts/BlissfullySmooth.ttf) format("truetype");
}

.flex-grow {
  flex-grow: 1;
}

.logoFont {
  font-family: "BlissfullySmooth";
  font-size: 50px;
  padding: 2px;
}

.pageTitle {
  font-size: 20px;
  padding: 2px;
}

.noStyle {
  text-decoration: none;
  font-style: normal;
  font-weight: bold;
}
</style>
