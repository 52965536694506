import Axios from "axios";

const RESOURCE = `${process.env.VUE_APP_BASE_URL}/api`;

export default {
  // saveCaptionData(captions, token) {
  //   const config = {
  //     headers: { Authorization: `Bearer ${token}` },
  //   };
  //   const payload = { captionData: captions };
  //   return Axios.post(`${RESOURCE}/set/setCaptionData`, payload, config);
  // },
  async readSingleJob(jobID, token) {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    const payload = { jobid: jobID };
    return await Axios.post(`${RESOURCE}/read/readSingleJob`, payload, config);
  },
  async readAllJobs(user, token) {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const data = { email: user };
    return await Axios.post(`${RESOURCE}/read/readAllJobs`, data, config);
  },
  createJob(payload, token) {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    return new Promise((resolve) => {
      resolve(Axios.post(`${RESOURCE}/create/createVideoJob`, payload, config));
    });
  },
  async updateJob(payload, token) {
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      return await Axios.post(`${RESOURCE}/update/updateVideoJob`, payload, config);
  },
  deleteJob(payload, token) {
    return new Promise((resolve) => {
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      resolve(Axios.post(`${RESOURCE}/delete/deleteJob`, payload, config));
    });
  },
  getRawCaptionData(signedS3URL) {
    // const config = {
    //   headers: { Authorization: `Bearer ${token}` },
    // };
    return new Promise((resolve) => {
      resolve(Axios.get(signedS3URL));
    });
  },
};
