import axios from "axios";

const RESOURCE = `${process.env.VUE_APP_BASE_URL}/api`;

export default {
  createUser(userData, token) {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    const payload = { user: userData };
    return axios.post(`${RESOURCE}/create/createUser`, payload, config);
  },
  readStripeData(payload, token) {
    return new Promise((resolve) => {
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      resolve(axios.post(`${RESOURCE}/read/readStripeData`, payload, config));
    });
  },
  async createNewStripeSubscription(userData, token) {
    return new Promise((resolve) => {
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      const payload = { user: userData };
      axios
        .post(`${RESOURCE}/create/createNewStripeSubscription`, payload, config)
        .then((response) => {
          resolve(response.data);
        });
    });
  },
  async readUserPortalLink(userData, token) {
    return new Promise((resolve) => {
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      const payload = { user: userData };
      axios
        .post(`${RESOURCE}/read/readUserPortalLink`, payload, config)
        .then((response) => {
          resolve(response);
        });
    });
  },
};
