import { createStore } from "vuex";
import UserService from "../api/user.service";
import JobService from "../api/jobs.service";

export default createStore({
  state: {
    isMenuDrawerOpen: false,
    navMenuIndex: 1,
    pageTitle: "",
    user: {
      nickname: "",
      name: "",
      picture: "",
      updated_at: "",
      email: "",
      email_verified: false,
      sub: "",
      auth0PasswordResetURL: process.env.VUE_APP_AUTH0RESETURL,
      stripeInfo: {},
    },
    jobs: [],
    selectedJob: {},
  },
  getters: {
    getMenuIndex(state) {
      return state.navMenuIndex;
    },
    getUser(state) {
      return state.user;
    },
    getPageTitle(state) {
      return state.pageTitle;
    },
    getJobs(state) {
      return state.jobs;
    },
    getSelectedJob(state) {
      return state.selectedJob;
    },
    getisMenuDrawerOpen(state) {
      return state.isMenuDrawerOpen;
    },
    getauth0PasswordResetURL(state) {
      return state.auth0PasswordResetURL;
    },
  },
  mutations: {
    setMenuIndex(state, index) {
      state.navMenuIndex = index;
    },
    setPageTitle(state, title) {
      state.pageTitle = title;
    },
    setUser(state, user) {
      state.user.nickname = user.nickname || state.user.nickname;
      state.user.name = user.name || state.user.name;
      state.user.picture = user.picture || state.user.picture;
      state.user.updates_at = user.updated_at || state.user.updates_at;
      state.user.email = user.email || state.user.email;
      state.user.sub = user.sub || state.user.sub;
    },
    setStripeData(state, stripeInfo) {
      state.user.stripeInfo = stripeInfo || state.user.stripeInfo;
    },
    setJobs(state, jobs) {
      state.jobs = jobs.data;
    },
    selectJob(state, jobData) {
      state.selectedJob = jobData;
    },
    setisMenuDrawerOpen(state) {
      state.isMenuDrawerOpen = !state.isMenuDrawerOpen;
    },
    setisMenuDrawerOpenToClosed(state) {
      state.isMenuDrawerOpen = false;
    },
  },
  actions: {
    async syncUser({ commit }, payload) {
      const response = await UserService.createUser(
        payload.user,
        payload.token
      );
      if (response.data.message === "Success") {
        commit("setUser", payload.user._value);
        UserService.readStripeData(payload.user._value, payload.token).then(
          (response) => {
            if (response.data.status === "complete") {
              commit("setStripeData", response.data.data);
            }
          }
        );
      }
    },
    async setSelectedJob({ commit }, payload) {
      const jobInfo = await JobService.readSingleJob(
        payload.jobID,
        payload.token
      );
      commit("selectJob", jobInfo.data.data.job);
    },
  },
  modules: {},
});
