<template>
  <div>
    <NavBar></NavBar>
    <router-view />
  </div>
</template>

<script>
import NavBar from "@/components/NavBar.vue";

export default {
  name: "App",
  components: { NavBar },
};
</script>

<style lang="less">

// ####################
// Positioning
// ####################
.floatLeft {
  float: left;
}
.floatRight {
  float: right;
}
.margin1 {
  margin: 1px;
}
.margin5 {
  margin: 5px;
}
.margin10 {
  margin: 10px;
}
.margin20 {
  margin: 20px;
}
.margin30 {
  margin: 30px;
}

// ####################
// Decoration
// ####################
.shadow {
  -webkit-box-shadow: 0px 0px 18px -4px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 18px -4px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 18px -4px rgba(0, 0, 0, 0.75);
}
.disabled {
  cursor: not-allowed;
}
.customButton{
  cursor: pointer;
  :hover {
    transform: scale(1.5);
  }
}
.linkNoDecoriation{
  text-decoration: none;
}

// ####################
// Components
// ####################
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}



nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
